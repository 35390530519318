.custom-navbar {
  width: 100%;
  height: 60px;
  padding: 15px 25px 15px 25px;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 2 !important;
  border-bottom: 1px solid rgb(214, 214, 214);
}

.custom-navbar-title {
  flex: 1;
  margin: 0;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 16px;
  color: #091d42;
}

.custom-navbar-links {
  margin: 0;
  list-style-type: none;
}

.custom-navbar-li {
  display: inline;
  margin-left: 30px;
}

.custom-navbar-link {
  font-size: 14px;
  /* text-transform: uppercase; */
  -webkit-transition: font-size 0.5s;
  transition: font-size 0.5s;
}

.custom-navbar-link:hover {
  color: #ef5f7a;
  text-decoration: none;
  font-size: 15px;
}

.custom-menu-navbar {
  width: 100%;
  height: auto;
  padding: 15px 25px 15px 25px;
  position: fixed;
  top: 0;
  z-index: 2 !important;
  border-bottom: 1px solid rgb(214, 214, 214);
}

.custom-menu-navbar-title {
  display: inline;
  position: relative;
  top: 2.5px;
  margin: 0;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 16px;
}

.custom-menu-navbar-icon {
  float: right;
}

.custom-menu-navbar-icon:hover {
  display: inline;
  cursor: pointer;
}

.custom-menu-navbar-menu-list-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 220px;
  animation: alternate-reverse;
}

.custom-menu-navbar-menu-list {
  padding: 0;
  margin-bottom: 0px;
  list-style-type: none;
}

.custom-menu-navbar-li {
  margin-bottom: 10px;
}

.custom-menu-navbar-link {
  font-size: 15px;
  /* text-transform: uppercase; */
  -webkit-transition: font-size 0.5s;
  transition: font-size 0.5s;
}

.custom-menu-navbar-link:hover {
  color: #ef5f7a;
  text-decoration: none;
  font-size: 16px;
}

#contactMeBtn {
  padding: 8px 15px 8px 15px;
  border-radius: 100px;
  font-size: 13px;
  /* text-transform: uppercase; */
  color: white;
  background-color: #ef5f7a;
}

#contactMeBtn:hover {
  text-decoration: none;
  background-color: #d7556e;
}

.home-intro-container {
  height: 600px;
  /* background-image: url("./assets/intro-bg.png"); */
  background-color: #f3f4fa;
  background-repeat: no-repeat;
  background-size: cover;
}

.home-intro-text-container {
  display: flex;
  align-content: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  text-align: start;
}

.home-intro-text-wrapper {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.home-intro-small {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
}

.home-intro-name {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
  margin-bottom: 10px;
}

.block {
  width: 35px;
  height: 6px;
  align-self: center;
}

.home-intro-headline {
  margin-top: 16px;
  text-align: center;
  font-size: 22px;
  letter-spacing: 1px;
}

.home-intro-btn {
  margin-top: 15px;
  border: none;
  font-size: 15px;
  padding: 10px 15px 10px 15px;
  border-radius: 100px;
  width: 170px;
  align-self: center;
}

.home-intro-btn:hover {
  background-color: #d7556e;
}

.home-intro-btn-outline {
  margin-top: 20px;
  border-color: #ef5f7a;
  border-width: 2px;
  font-size: 15px;
  padding: 10px 15px 10px 15px;
  border-radius: 100px;
  width: 170px;
  align-self: center;
  color: #091d42;
}

.home-intro-btn-outline:hover {
  background-color: #ef5f7a;
  border-color: #ef5f7a;
  border-width: 2px;
  color: white;
}

.home-about-me-container {
  padding: 35px 25px 35px 25px;
}

.home-about-me-header-container {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}

.home-section-title {
  text-align: center;
  font-size: 35px;
  text-transform: uppercase;
  font-weight: bold;
}

.section-block {
  width: 35px;
  height: 6px;
  align-self: center;
}

.home-about-me-content-container {
  margin-top: 40px;
}

.home-about-me-content-one {
  text-align: center;
  margin-top: 30px;
}

.home-about-me-text {
  margin: 0;
}

.home-about-me-content-two {
  margin-top: 60px;
  text-align: center;
}

.home-about-me-img {
  width: 280px;
  height: 280px;
  border-radius: 250px;
  border: 2px solid #091d42;
}

.home-my-skills-container {
  padding: 35px 25px 35px 25px;
}

.home-my-skills-header-container {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}

.home-my-skills-content-one {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.home-my-skills-skill-list {
  list-style-type: none;
  /* text-align: center; */
}

.home-my-skills-skill-item::before {
  content: "• ";
  color: #ef5f7a;
}

.home-my-skills-skill-item {
  margin-bottom: 25px;
}

.custom-footer-container {
  padding: 35px 25px 35px 25px;
}

.custom-footer-social-media-list-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-footer-social-media-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.custom-footer-social-media-item {
  display: inline;
  margin-left: 15px;
  margin-right: 15px;
}

.portfolio-container {
  padding: 35px 25px 35px 25px;
}

.portfolio-header-container {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}

.portfolio-title {
  text-align: center;
  font-size: 35px;
  text-transform: uppercase;
  font-weight: bold;
}

.portfolio-content-one {
  text-align: center;
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "portfolioone portfoliotwo";
}

.portfolio-one-wrapper {
  height: auto;
  grid-area: portfolioone;
  margin-right: 10px;
  padding: 15px;
}

.portfolio-two-wrapper {
  height: auto;
  grid-area: portfoliotwo;
  margin-left: 10px;
  padding: 15px;
}

.portfolio-section-header-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.portfolio-section-title {
  font-weight: bold;
  margin-bottom: 15px;
}

.portfolio-one-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.portfolio-one-list-item-title {
  font-size: 20px;
  font-weight: bold;
}

.portfolio-one-list-item-title:hover {
  color: #091d42;
}

.portfolio-one-list-item-description {
  margin-top: 5px;
}

.portfolio-two-list-item-title {
  font-size: 20px;
  font-weight: bold;
}

.portfolio-two-list-item-title:hover {
  color: #091d42;
}

.portfolio-two-list-item-description {
  margin-top: 5px;
}

.portfolio-two-list-item {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact-me-container {
  padding: 35px 25px 35px 25px;
}

.contact-me-header-container {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}

.contact-me-title {
  text-align: center;
  font-size: 35px;
  text-transform: uppercase;
  font-weight: bold;
}

.contact-me-content-one {
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.contact-me-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact-me-input {
  padding: 10px 10px;
  width: 400px;
  height: 40px;
  margin-bottom: 15px;
  border: none;
  background-color: #fff;
  border-radius: 5px;
}

.contact-me-input:focus {
  outline: none;
  border: 2px solid #ef5f7a;
  border-radius: 5px;
}

.contact-me-textarea {
  padding: 10px 10px;
  width: 400px;
  width: 400px;
  height: 130px;
  border: none;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 15px;
}

.contact-me-textarea:focus {
  outline: none;
  border: 2px solid #ef5f7a;
  border-radius: 5px;
}

.contact-me-btn {
  width: 400px;
  height: 40px;
  color: white;
  border: none;
  border-radius: 5px;
}

.contact-me-btn:hover {
  background-color: #d7556e;
}

.contact-me-btn:focus {
  outline: none;
}

@media screen and (max-width: 768px) {
  .portfolio-content-one {
    text-align: center;
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "portfolioone"
      "portfoliotwo";
  }
  .portfolio-one-wrapper {
    height: auto;
    grid-area: portfolioone;
    padding: 15px;
    margin-bottom: 15px;
    margin-right: 0px;
  }

  .portfolio-two-wrapper {
    height: auto;
    grid-area: portfoliotwo;
    padding: 15px;
    margin-left: 0px;
  }

  .contact-me-input {
    padding: 10px 10px;
    width: 300px;
    height: 40px;
    margin-bottom: 15px;
    border: none;
    background-color: #fff;
    border-radius: 5px;
  }

  .contact-me-textarea {
    padding: 10px 10px;
    width: 300px;
    height: 130px;
    border: none;
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 15px;
  }

  .contact-me-btn {
    width: 300px;
    height: 40px;
    color: white;
    border: none;
    border-radius: 5px;
  }
}

@media screen and (min-width: 1016px) {
  .home-about-me-content-container {
    padding-left: 180px;
    padding-right: 180px;
  }
  .home-about-me-img {
    width: 230px;
    height: 230px;
  }
}

@media screen and (min-width: 768px) {
  .home-about-me-content-one {
    align-self: center;
  }
  .home-about-me-content-two {
    margin-top: 0px;
    align-self: center;
    text-align: start;
    /* text-align: center; */
  }
}
